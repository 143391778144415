<template>
    <div class="rounded-md p-4 text-sm my-2 border" role="alert" :class="getClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "alert",
    props: {
        success: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        warning: {
            type: Boolean,
            default: false
        },
        info: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        getClass() {
            return {
                'alert': true,
                'alert-success': this.success,
                'alert-error': this.error,
                'alert-warning': this.warning,
                'alert-info': this.info
            };
        }
    }
};
</script>