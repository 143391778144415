<template>
  <div>
    {{ title }}
  </div>
</template>

<script>

export default {
  props: ["id"],
  mounted() {
  },
  computed: {
    title() {
      if (this.id) {
        let store = this.$stores.find(item => item.id == this.id);
        if (store) {
          return store.title;
        }
      }
      return this.id;
    }
  }
};
</script>
