<template>
    <page>
        <template v-slot:header>
            Отчеты
        </template>
        <template v-slot:actions>
        </template>

        <template v-slot:right>
        </template>
        <!-- Filters, collapsable -->
        <template v-slot:content>

            <div class="flex flex-row items-center space-x-8 relative">
                <div class="">
                    <div class="flex flex-row">
                        <div class="form-label">Период</div>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <input type="date" v-model="filter.dateStart" class="ui-selector" @change="setPeriod('')" />
                        <input type="date" v-model="filter.dateEnd" class="ui-selector" @change="setPeriod('')" />
                    </div>
                    <div class="periods flex flex-row space-x-2 mt-2 absolute">
                        <button @click="setPeriod('today')" :class="{ active: period == 'today' }">Сегодня</button>
                        <button @click="setPeriod('week')" :class="{ active: period == 'week' }">За 7 дней</button>
                        <button @click="setPeriod('months-1')" :class="{ active: period == 'months-1' }">За
                            месяц</button>
                        <button @click="setPeriod('months-3')" :class="{ active: period == 'months-3' }">За 3
                            месяца</button>
                        <button @click="setPeriod('months-6')"
                            :class="{ active: period == 'months-6' }">Полгода</button>
                        <button @click="setPeriod('months-12')" :class="{ active: period == 'months-12' }">Год</button>
                    </div>
                </div>
                <div class="">
                    <span class="form-label">Филиал</span>
                    <select class="ui-selector" v-model="filter.storeId" @change="loadStat()">
                        <option value="">Все филиалы</option>
                        <option :value="store.id" v-for="store in $stores">{{ store.title }}</option>
                    </select>
                </div>

                <div class="">
                    <span class="form-label">Сотрудник</span>
                    <select class="ui-selector" v-model="filter.userId" @change="loadStat()">
                        <option value="">Все сотрудники</option>
                        <option :value="user.id" v-for="user in $users">{{ user.name }}</option>
                    </select>
                </div>

            </div>

            <div class="relative">
                <loading v-if="busy" />

                <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-12">
                    <div class="">
                        <span class="font-semibold">По филиалам</span>
                        <table class="w-full data-table mt-2">
                            <thead class="">
                                <tr class="">
                                    <th>Филиал</th>
                                    <th>Всего продаж</th>
                                    <th>Выручка</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in sortedSalesByStores">
                                    <td><store-label :id="row.storeId" /></td>
                                    <td>{{ row.sales }}</td>
                                    <td>{{ row.total | money }} руб.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <span class="font-semibold">По сотрудникам</span>
                        <table class="w-full data-table mt-2">
                            <thead class="">
                                <tr class="">
                                    <th>Филиал</th>
                                    <th>Сотрудник</th>
                                    <th>Всего продаж</th>
                                    <th>Выручка</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in sortedSalesByUsers">
                                    <td><store-label :id="row.storeId" /></td>
                                    <td><user-card :id="row.userId" /></td>
                                    <td>{{ row.sales }}</td>
                                    <td>{{ row.total | money }} руб.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="mt-8">
                    <span class="font-semibold">Отчет по товарам</span>
                    <table class="w-full data-table mt-2">
                        <thead class="">
                            <tr class="">
                                <th>
                                    Товар
                                    <input type="text" v-model="term" class="ui-selector ml-2"
                                        placeholder="Поиск по названию" />
                                </th>
                                <th>Всего продаж</th>
                                <th>Штук продано</th>
                                <th>Выручка</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in sortedProducts">
                                <td>{{ row.productName }}</td>
                                <td>{{ row.sales }}</td>
                                <td>{{ row.qty }}</td>
                                <td>{{ row.total | money }} руб.</td>
                            </tr>
                        </tbody>

                    </table>
                </div>

            </div>
        </template>
    </page>
</template>

<script>
import dayjs, { utc } from "dayjs";

export default {
    name: "t",
    components: {
    },
    data() {
        return {
            filter: {
                dateStart: dayjs().subtract(7, "day").startOf("day").format("YYYY-MM-DD"),
                dateEnd: dayjs().endOf("day").format("YYYY-MM-DD"),
                storeId: '',
                userId: '',
            },
            stat: {
                byStores: [],
                byUsers: [],
                byProducts: []
            },
            period: 'week',
            busy: false,
            term: '' // filter products by name
        }
    },
    mounted() {
        this.loadStat();
    },
    methods: {
        setPeriod(period) {
            this.period = period;

            let months = period.split('-');
            if (period == 'today') {
                this.filter.dateStart = dayjs().startOf("day").format("YYYY-MM-DD");
                this.filter.dateEnd = dayjs().endOf("day").format("YYYY-MM-DD");
            }

            if (period == 'week') {
                this.filter.dateStart = dayjs().subtract(7, "day").startOf("day").format("YYYY-MM-DD");
                this.filter.dateEnd = dayjs().endOf("day").format("YYYY-MM-DD");
            }

            if (months.length == 2) {
                months = months[1]
                this.filter.dateStart = dayjs().subtract(months, "month").startOf("day").format("YYYY-MM-DD");
                this.filter.dateEnd = dayjs().endOf("day").format("YYYY-MM-DD");
            }

            this.loadStat();
        },
        async loadStat() {
            this.busy = true;
            let query = encodeURIComponent(JSON.stringify(this.filter));
            let { success, msg, data } = await this.$api.get(`/reports?filter=${query}`);

            if (success) {
                this.stat = data;
            }

            setTimeout(() => {
                this.busy = false;
            }, 500);
        }
    },
    computed: {
        sortedProducts() {

            let arr = [...this.stat.byProducts]

            if (this.term) {
                arr = arr.filter(el => el.productName.toLowerCase().includes(this.term.toLowerCase()));
            }

            return arr.sort((a, b) => {
                if (a.sales < b.sales) return -1;
                if (a.sales > b.sales) return 1;
                return 0;
            });
        },
        sortedSalesByStores() {

            let arr = [...this.stat.byStores]
            return arr.sort((a, b) => {
                if (a.sales < b.sales) return -1;
                if (a.sales > b.sales) return 1;
                return 0;
            })


        },
        sortedSalesByUsers() {
            let arr = [...this.stat.byUsers]

            return arr.sort((a, b) => {
                if (a.sales < b.sales) return -1;
                if (a.sales > b.sales) return 1;
                return 0;
            })

        },

    }
}
</script>