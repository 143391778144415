<template>
    <div>
      <div class="bg-white px-5 py-4 rounded-md w-1/3">
        <h2 class="font-semibold text-lg mb-4 flex flex-row items-center">
          Введите данные для входа в ваш аккаунт itigris
        </h2>

        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_login">Логин</label>
          <input class="w-3/3" v-model="itigris.login" type="text" id="itigris_login" />
        </div>

        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_password">Пароль</label>
          <input class="w-3/3" v-model="itigris.password" type="text" id="itigris_password" />
        </div>

        <!--      <error v-if="tgError">{{ tgError }}</error>-->
        <button class="btn-action mt-4" @click="saveSettings()"
                :class="{ busy: busy }">Сохранить</button>
      </div>
        <template v-if="itigris.login && itigris.password">
          <div class="px-5 mb-4">Аккаунт Itigris: {{ itigris.login }} <a class="b-link" href="#" @click.prevent="itigris.login=null">изменить</a></div>
            <div class="mb-4">
              <button class="btn-action" :disabled="createTaskBusy " @click="createImportTask()"
                      :class="{ busy: createTaskBusy }">
                <img src="/img/refresh.svg" class="w-4 inline-block mr-0.5"/>Создать задачу на обновление
              </button>
          </div>
          <button class="btn-default" @click="refreshTasks()"
                  :class="{ busy: createTaskBusy }">
            <img src="/img/refresh.svg" class="w-4 inline-block mr-0.5"/> Обновить таблицу
          </button>
            <div class="bg-white">
              <table class="data-table">
                <thead>
                <tr>
                  <th rowspan="2"></th>
                  <th rowspan="2">Задание</th>
                  <th colspan="2">Всего обработано</th>
                  <th colspan="2">Добавлено</th>
                  <th colspan="2">Обновлено</th>
                  <th colspan="2">С ошибками</th>
                </tr>
                <tr>
                  <th>Заказы</th>
                  <th>Покупатели</th>
                  <th>Заказы</th>
                  <th>Покупатели</th>
                  <th>Заказы</th>
                  <th>Покупатели</th>
                  <th>Заказы</th>
                  <th>Покупатели</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="itigrisTasksBusy"><td>load ...</td></tr>
                <tr v-for="item in itigrisTaskList" class="">
                  <td class="text-center">
                    <template v-if="item.status == 'done'">✅</template>
                    <template v-if="item.status == 'completed'">✅</template>
                    <template v-if="item.status == 'processing'">⏳</template>
                    <template v-if="item.status == 'new'">⏱️</template>
                    <div
                    v-if="['new','processing'].includes(item.status)"
                    title="удалить"
                    class="cursor-pointer px-1 text-red-600 hover:text-red-800"
                    @click="deleteTask(item.id)"
                  >удалить</div>
                  </td>
                  <td>{{ item.created_at | datetime }} Синхронизация заказов и покупателей </td>
                  <template v-if="item.result">
                  <td><span v-if="item.result.importSaleResult">{{ item.result.importSaleResult.processed || 0 }}</span><span v-else>--</span></td>
                  <td><span v-if="item.result.importCustomerResult">{{ item.result.importCustomerResult.processed ||0 }}</span><span v-else>--</span></td>

                  <td><span v-if="item.result.importSaleResult">{{ item.result.importSaleResult.added ||0 }}</span><span v-else>--</span></td>
                  <td><span v-if="item.result.importCustomerResult">{{ item.result.importCustomerResult.added || 0 }}</span><span v-else>--</span></td>

                  <td><span v-if="item.result.importSaleResult">{{ item.result.importSaleResult.updated || 0 }}</span><span v-else>--</span></td>
                  <td><span v-if="item.result.importCustomerResult">{{ item.result.importCustomerResult.updated || 0 }}</span><span v-else>--</span></td>

                  <td><span v-if="item.result.importSaleResult">{{ item.result.importSaleResult.failedItems.length || 0 }}</span><span v-else>--</span></td>
                  <td><span v-if="item.result.importCustomerResult">{{ item.result.importCustomerResult.failedItems.length || 0 }}</span><span v-else>--</span></td>
                  </template>
                  <template v-else>
                    <td colspan="8" class="text-center">Задача не завершена</td>
                  </template>
                </tr>
                </tbody>
              </table>
            </div>

        </template>
    </div>
</template>

<script>
export default {
    props: ["settings"],
    data() {
        return {
          itigris: {
            login: "",
            password: ""
          },
          busy: false,
          itigrisTaskList:[],
          itigrisTasksBusy: false,
          createTaskBusy: false,
        };
    },
    mounted() {
      this.getTaskList()
      this.getSettings();
    },
    methods: {
      async getSettings() {
        let { success, msg, data } = await this.$api.get("/settings?type=itigris");
        if (success || data.data) {
          this.itigris = data.data;
        }
      },
      async getTaskList() {
        this.itigrisTasksBusy = true;
        let { success, msg, data } = await this.$api.get("/itigris/tasks");

        if (success) {
          this.itigrisTaskList = data.items;
        }
        this.itigrisTasksBusy = false;

      },
      async saveSettings() {
        this.itigrisBusy = true;
        this.status = "";
        this.itigrisError = "";

        let { success, msg, data } = await this.$api.post("/settings/itigris", {
          data: {
            login: this.itigris.login,
            password: this.itigris.password
          }
        });

        if (data.data) {
          this.status = "success";
        } else {
          this.itigrisError = msg;
        }

        setTimeout(() => {
          this.itigrisBusy = false;
        }, 250);
      },
      async createImportTask() {
        this.createTaskBusy = true;
        let { success, msg, data } = await this.$api.post("/itigris/tasks",{});
        if (success) {
          await this.getTaskList();
        } else {
          alert(msg)
        }
        this.createTaskBusy = false;
      },
      async deleteTask(task_id) {

        this.createTaskBusy = true;
        let { success, msg, data } = await this.$api.delete("/itigris/tasks",{task_id});
        if (success) {
          await this.getTaskList();
        } else {
          alert(msg)
        }
        this.createTaskBusy = false;
      },
      refreshTasks() {
        this.getTaskList()
      }
    }
}
</script>