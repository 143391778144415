<template>
    <button class="btn-primary" @click="$emit('click')" :disabled="busy">
        <slot />
    </button>
</template>

<script>
export default {
    props: ['busy'],
    data() {
        return {
        }
    }
}
</script>