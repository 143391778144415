<template>
  <div class="overflow-x-auto">
    <div class="w-full border bg-white rounded-md">
<!--      <pre>{{customer}}</pre>-->
      <table class="data-table">
        <thead>
        <tr class="font-semibold text-xs text-gray-600">
          <th class="px-4 py-3">Информация</th>
          <th class="">Параметры</th>
          <th class="">Описание</th>
        </tr>
        </thead>
        <tbody class="text-sm">
        <tr v-for="item in customer.metrics">
          <td>
            <div class="font-semibold mb-2"><span>{{ item.createdOn }}</span></div>
            <div class="font-semibold mb-2"><span>Выписал: {{ item.userId }}</span></div>
            <div class="text-xs" v-if="item.modelLens"><span>Контактные линзы</span></div>
            <div class="text-xs" v-else><span>Очки</span></div>
            <div class="font-semibold" v-if="item.modelLens"><span>{{ item.modelLens }}</span></div>

            <div class="mt-2 opacity-20">
              <span class="text-xs">{{(item.userId) ? item.userId : "* добавлено через интеграцию"}}</span>
            </div>

<!--            <button type="button" class="py-1 px-4 bg-red-100 text-red-500 rounded-md font-medium text-xs opacity-50 hover:opacity-100"> Удалить </button>-->
          </td>
          <td>
            <div class="grid grid-cols-6 text-xs font-semibold text-gray-600 mb-2 text-center">
              <div></div>
              <div>Sph</div>
              <div>Cyl</div>
              <div>Axis</div>
              <div>Add</div>
              <div>Dpp</div>
            </div>
            <div class="grid grid-cols-6 gap-x-2" v-if="item.refractometria">
              <!-- od -->
              <div>OD</div>
              <div>
                <autocomplete v-model="item.refractometria.od.sph" type="sph" format="float" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.od.cyl" type="cyl" format="float" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.od.axis" type="axis" :field="'glasses'" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.od.add" type="add" format="float" />
              </div>
              <div class="">
                <autocomplete v-model="item.refractometria.od.dpp" type="dpp" format="float" />
              </div>
              <!-- os -->
              <div>OS</div>
              <div>
                <autocomplete v-model="item.refractometria.os.sph" type="sph" format="float" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.os.cyl" type="cyl" format="float" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.os.axis" type="axis" format="float" :field="'glasses'" />
              </div>
              <div>
                <autocomplete v-model="item.refractometria.os.add" type="add" />
              </div>
              <div class="">
                <autocomplete v-model="item.refractometria.os.dpp" type="dpp" format="float" />
              </div>
            </div>
          </td>
          <td>
            <input
              class="mb-3 w-full py-1 px-1 lg:px-2 text-xs lg:text-sm focus:ring-2 ring-indigo-500"
              type="text"
              required=""
              v-model="item.purpose"
              ref="input"
              id="purpose"
              placeholder="Назначение"
            />
            <textarea id="comment" class="w-full" type="text" required="" v-model="item.comment" placeholder="Заметка" />
          </td>
        </tr>
        </tbody>
      </table>
      <div class="px-3 py-2">
        <button type="button" class="text-xs text-blue-500" @click="customer.metrics.push({})">
          + Добавить рецепт
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "customer"],
  data() {
    return {
    };
  },
  name: "customer-prescription",
  mounted() {
  },
  methods: {

  },
  computed: {}
};
</script>
